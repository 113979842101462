var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          columns: _vm.columns,
          "data-source": _vm.list,
          scroll: { x: 800 },
          rowKey: function(record, n) {
            return n
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "authBigCoinSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.coinKindList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.coinKndId,
                      staticClass: "tag-class",
                      attrs: { color: "blue" }
                    },
                    [_vm._v(_vm._s(item.coinKindName))]
                  )
                }),
                1
              )
            }
          },
          {
            key: "authActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowEditor(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定删除？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleDeleteAuth(row)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "small", type: "danger" }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("ExpertOrNormalAdd", {
        ref: "expertOrNormalAddEl",
        on: { addOrEditorSuccess: _vm.handleAddOrEditorSuccess }
      }),
      _c("RatingAdd", {
        ref: "ratingAddEl",
        on: { addOrEditorSuccess: _vm.handleAddOrEditorSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }