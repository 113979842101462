import instance from "@/axios"

/** 编辑评级权限 */
export const postEditorRatingAuth = async (params) => {
    return instance({
        url: '/dq_admin/duibanRatingAuthCoin/editAuthority',
        method: 'GET',
        params: params
    })
}
/** 增加评级权限 */
export const postAddRatingAuth = async (params) => {
    return instance({
        url: '/dq_admin/duibanRatingAuthCoin/addAuthority',
        method: 'GET',
        params: params
    })
}
/** 删除评级权限 */
export const deleteRatingAuthList = async (apiUrl, params) => {
    return instance({
        url: apiUrl,
        method: 'GET',
        params: params
    })
}

/** 搜索朝代 */
export const getDynastyList = async (params) => {
    return instance({
        url: '/dq_admin/dynasty/searchDynasty',
        method: 'GET',
        params: params
    })
}

/** 搜索朝代拥有的大币种 */
export const getDynastyBigCoinAbout = async (params) => {
    return instance({
        url: '/dq_admin/kind/listCoinListByDynastyId',
        method: 'GET',
        params: params
    })
}